// ###########################################################################
// HEADER - Add class .min to scroll down
$(document).ready(function() {
  $(window).scroll(function() {
    // Calculate the scroll position
    var scrollPosition = $(window).scrollTop();

    // Check if the user has scrolled 300 pixels
    if (scrollPosition > 300) {
      // Add the 'min' class to the header
      $('.main-header').addClass('min');
    } else {
      // Remove the 'min' class if not scrolled 300 pixels
      $('.main-header').removeClass('min');
    }
  });
});

// ###########################################################################
// ANIMATION FADE TO TOP - element dom
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

$(document).on("sf:ajaxfinish", ".searchandfilter", function(){
	console.log("ajax complete");
	//so load your lightbox or JS scripts here again
  $('.animated').addClass("active");
});

// ###########################################################################
// PRIMARY NAVIGATION
$("#btnMobile").click(function (e) {
  $(this).toggleClass("cross");
  $(".main-nav-wrapper").toggleClass("open");
  $("body").toggleClass("overflow");
});

// ###########################################################################
// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }
    $(this).parent().toggleClass("openSub-menu");
});

// CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".menu-item-has-children").removeClass("openSub-menu");
    }
});

// ###########################################################################
// ANCRE
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var nav = $('nav');
    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top - nav.height()
    }, 1200, 'swing', function () {
        window.location.hash = target;
    });
});

// ###########################################################################
// SLICK - slideshow & slider
$(document).ready(function(){

  $('.slideshow .slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });

});


document.addEventListener('DOMContentLoaded', function () {
    Fancybox.bind('[data-fancybox="gallery"]', {
        Thumbs: {
            autoStart: true, // Active la navigation par miniatures
        },
    });
});
